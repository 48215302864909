<template>
  <div class="admin-home">
    <div>
      <left-menu :menus="items" userType="admin" />
    </div>
    <div class="wrap">
      <topBar />
      <div class="content">
        <div class="page">
          <router-view />
        </div>
        <div class="footer">
          <Footer/>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { h, reactive } from "vue";
import leftMenu from "~/components/LeftMenu.vue";
import topBar from "~/components/topBar.vue";
import Footer from "~/components/Footer.vue";

const items = reactive([
  {
    key: "/operator/users",
    icon: () =>
      h("svg", { class: "icon", "aria-hidden": "true" }, [
        h("use", { "xlink:href": "#icon-home" }),
      ]),
    routeName: "users",
    title: "Users",
    label: "Users",
  },
  {
    key: "/operator/seller",
    icon: () =>
      h("svg", { class: "icon", "aria-hidden": "true" }, [
        h("use", { "xlink:href": "#icon-shangjia" }),
      ]),
    routeName: "seller",
    title: "Sellers",
    label: "Sellers",
  },
  {
    key: "/operator/seller-report",
    icon: () =>
      h("svg", { class: "icon", "aria-hidden": "true" }, [
        h("use", { "xlink:href": "#icon-chart" }),
      ]),
    routeName: "seller-report",
    title: "Sellers' Report",
    label: "Sellers' Report",
  },
  {
    key: "/operator/publisher",
    icon: () =>
      h("svg", { class: "icon", "aria-hidden": "true" }, [
        h("use", { "xlink:href": "#icon-a-wode4" }),
      ]),
    routeName: "publisher",
    title: "Publisher",
    label: "Publisher",
  },
  {
    key: "/operator/commissions",
    icon: () =>
      h("svg", { class: "icon", "aria-hidden": "true" }, [
        h("use", { "xlink:href": "#icon-baifenhao" }),
      ]),
    routeName: "commissions",
    title: "Commissions",
    label: "Commissions",
  },
  {
    key: "/operator/finance",
    icon: () =>
      h("svg", { class: "icon", "aria-hidden": "true" }, [
        h("use", { "xlink:href": "#icon-jine" }),
      ]),
    label: 'Finance',
    children: [
      {
        routeName: "finance-payments",
        key: "/operator/finance/payments",
        preKey: "/operator/finance",
        label: "Payments",
        title: "Payments",
      },
      // {
      //   preKey: "/operator/finance",
      //   routeName: "finance-transactions",
      //   key: "/operator/finance/transactions",
      //   label: "Transactions",
      //   title: "Transactions",
      // },
      // {
      //   preKey: "/operator/finance",
      //   routeName: "finance-manually-actions",
      //   key: "/operator/finance/manually-actions",
      //   label: "Manually Actions",
      //   title: "Manually Actions",
      // },
      // {
      //   preKey: "/operator/finance",
      //   routeName: "finance-saas-plan",
      //   key: "/operator/finance/saas-plan",
      //   label: "SAAS Plan",
      //   title: "SAAS Plan",
      // },
    ],
  },
]);
</script>
  <style lang="less" scoped>
.admin-home {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  // overflow: hidden;
  // min-width: 1440px;
  overflow-x: scroll;
  overflow-y: scroll;
  background-color: #f3f2f7;
  .wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 1176px;
    background-color: #f3f2f7;
    .content {
      flex: 1;
      overflow: auto;
      .page {
        min-height: calc(100% - 72px);
      }
    }
  }
}
</style>
  