<template>
  <div ref="newUserModalRef" class="new-user-modal">
    <artmisads-modal :open="open" :width="1200" :closable="true" :getContainer="() => $refs.newUserModalRef"
      @cancel="() => emit('hide')" :footer="null">
      <div class="new-user-cont">
        <div class="bg">
          <img class="bg1" alt="artemisAds" src="../assets/images/new-user-modal/new_user_modal_bg1.png" />
          <img class="bg2" alt="artemisAds" src="../assets/images/new-user-modal/new_user_modal_bg2.png" />
        </div>
        <div class="cont">
          <div class="head">
            <p class="title">🤙 Contact Us</p>
          </div>
          <div class="main-cont">
            <p>Please don't hesitate to contact us - <br>
              we're dedicated to addressing all your needs and supporting your success.</p>
            <div class="contact-wrapper">
              <div class="qrlist">
                <div class="qr-item" v-for="item in ['Slack', 'WhatsApp', 'Wechat', 'Lark']" :key="item">
                  <div class="logo">
                    <svg v-if="item == 'Slack' || item == 'Lark'" :class="'icon ' + item.toLowerCase()"
                      aria-hidden="true" style="fill: #fff">
                      <use :xlink:href="'#icon-' + item.toLowerCase()" />
                    </svg>
                    <i v-else :class="'iconfont icon-' + item.toLowerCase()" />{{ item }}
                  </div>
                  <div class="qrcode">
                    <img v-if="item == 'Slack'" src="../assets/images/social-media-qrcode/slack.png" :alt="item" />
                    <img v-else-if="item == 'WhatsApp'" src="../assets/images/social-media-qrcode/whatsapp.png"
                      :alt="item" />
                    <img v-else-if="item == 'Wechat'" src="../assets/images/social-media-qrcode/wechat.png"
                      :alt="item" />
                    <img v-else-if="item == 'Lark'" src="../assets/images/social-media-qrcode/lark.png" :alt="item" />
                    <p>Scan the QR code</p>
                  </div>
                </div>
              </div>
              <p>Also you can contact us via email：support@artemisads.com</p>
            </div>
          </div>
        </div>
        <div class="btn">
          <artmisads-button type="primary" @click="() => emit('hide')">I know</artmisads-button>
        </div>
      </div>
    </artmisads-modal>
  </div>
</template>

<script setup>
import { ref } from "vue";

const { open } = defineProps(['open']);
const emit = defineEmits(['hide']);

</script>

<style lang="less" scoped>
.new-user-modal {
  :deep(.ant-modal) {
    .ant-modal-body {
      padding: 0;
    }
  }

  .new-user-cont {
    position: relative;
    background-image: linear-gradient(170deg, #E2D9FC 0%, #FCF0FA 16%, #FBFAFF 41%, #FBFBFE 100%);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 16px 48px 0 rgba(0, 0, 0, 0.1), inset 2px 2px 0 0 rgba(255, 255, 255, 0.6);
    border-radius: 8px;

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 0;

      .bg1 {
        width: 324px;
        height: 247px;
      }

      .bg2 {
        width: 125px;
        height: 125px;
        margin-right: 49px;
        margin-top: 9px;
      }
    }
  }

  .cont {
    padding: 40px 40px 0 40px;
  }

  .head {
    .title {
      color: var(--dark-1);
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0.69px;
      text-align: left;
    }

    .text {
      font-size: 16px;
      margin-top: 5px;
      font-weight: 400;
      line-height: 24px;
      color: var(--dark-2);
      letter-spacing: 0.4px;
    }
  }

  .step-wrap {
    margin-top: 12px;
    margin-bottom: 32px;
  }

  .btn {
    display: flex;
    margin: 0 auto;
    align-items: center;
    padding: 32px 0 40px;
    justify-content: center;

    >button {
      width: 224px;
    }
  }
}

.main-cont {
  z-index: 1;
  margin-top: 24px;
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 40px 80px 0;

  >p {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: var(--dark-1);
  }

  .contact-wrapper {
    border-radius: 8px;
    margin: 24px 96px 0;
    padding: 30px 48px 0;
    background: var(--bg-gray-8);

    >p {
      padding: 24px 0;
    }
  }
}

.qrlist {
  gap: 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .qr-item {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .logo {
      gap: 6px;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      color: var(--dark-3);
      letter-spacing: 0.4px;
      align-self: flex-start;

      >.icon,
      >.iconfont {
        width: 32px;
        height: 32px;
        padding: 4px;
        border-radius: 8px;
        background-color: #fff;
      }

      >.iconfont {
        color: #fff;
        font-size: 24px;
        font-weight: normal;
        background-color: #25D366;

        &::before {
          display: block;
          margin-top: -6px;
        }
      }
    }

    .qrcode {
      padding: 10px;
      text-align: center;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 1px 0 0 var(--section-box-shadow-2);

      >img {
        width: 112px;
        height: 112px;
      }

      >p {
        font-size: 11px;
        margin-top: 7px;
        color: var(--dark-3);
        letter-spacing: 0.28px;
      }
    }
  }
}
</style>
